:root {
    --color-green: rgb(37, 134, 71);
    --color-blue: rgb(137, 203, 236);
    --color-yellow: rgb(248, 198, 76);
    --color-purple: rgb(170, 69, 152);
}

.other-player-hands-container {
    display: flex;
    justify-content: center;
}

.other-player-hand-container {
    border: solid 2px var(--color-green);
    margin: 2px;
    width: 355px;
    overflow: visible;
}

.other-player-hand-name-card-count-container {
    display: flex;
    justify-content: center;
}

.other-player-hand-name-card-count-text {
    margin: auto 0 auto 55px;
    font-size: 30px;
}


.play-area-right-column {
    position: absolute;
    right: 0;
    transform: rotate(270deg);
    top: 205px;
    display: flex;
    max-width: 355px;
}

.play-area-left-column {
    position: absolute;
    rotate: 90deg;
    top: 205px;
    left: 0;
}

.other-player-hand-name-container {
    display: flex;
    justify-content: center;
}

.other-player-hand-name-turn-icon {
    margin-left: 3px;
    margin-top: 1px;
    font-size: 20px;
    background-color: var(--color-blue);
    border-radius: 50%;

    animation-name: icon-colors;
    animation-duration: 10s;
    animation-iteration-count: infinite;
}

.player-is-offering-card {
    color: white;
    background-color: gray;
    cursor: pointer;
}

.player-is-offering-card>img {
    filter: brightness(50%);
}

@keyframes icon-colors {
    0% {
        background-color: var(--color-blue);
    }

    25% {
        background-color: var(--color-green);
    }

    50% {
        background-color: var(--color-purple);
    }

    75% {
        background-color: var(--color-yellow);
    }

    100% {
        background-color: var(--color-blue);
    }
}