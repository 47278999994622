.current-player-heated-cards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
}

.current-player-hand {
    padding: 30px;
}

.player-heated-card {
    width: 7rem;
    margin-right: -45px;
    cursor: pointer;
    position: relative;
    z-index: 0;
    transition: transform .4s ease;
}

.player-heated-card.this-card-has-been-offered {
    filter: brightness(50%);
}

.player-heated-card:hover {
    z-index: 1;
}

.sortable-card-container {
    position: relative;
}

.sortable-card-container:hover {
    z-index: 1;
}

.player-hand-card-info-icon {
    position: absolute;
    bottom: 10px;
    left: 10px;
    z-index: 3;
    background-color: white;
    background: white;
    background-size: auto;
    border-radius: 50%;
    border: solid 1px black;
}

.player-heated-card-offered-text {
    margin: none;
    font-size: 28px;
    transform: rotate(-45deg);
    position: absolute;
    bottom: 43px;
    right: -49px;
    color: white;
    font-weight: bold;
    /* // this long style does the black outline of the text */
    text-shadow: 0px 1px 2px #000, 1px 0px 2px #000, 0px -1px 2px #000, -1px -0px 2px #000, 1px 1px 2px #000, 0px 1px 2px #000, 1px 0px 2px #000, 0px -1px 2px #000, -1px -0px 2px #000, 1px 1px 2px #000, 0px 1px 2px #000, 1px 0px 2px #000, 0px -1px 2px #000, -1px -0px 2px #000, 1px 1px 2px #000, 0px 1px 2px #000, 1px 0px 2px #000, 0px -1px 2px #000, -1px -0px 2px #000, 1px 1px 2px #000;
}