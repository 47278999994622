:root {
    --color-green: rgb(37, 134, 71);
    --color-blue: rgb(137, 203, 236);
    --color-yellow: rgb(248, 198, 76);
    --color-purple: rgb(170, 69, 152);
}

.play-area-and-players {
    position: relative;
}

.center-row-play-area {
    display: flex;
    margin-top: 30px;
    justify-content: center;
}

.play-area {
    display: flex;
    justify-content: center;
    max-width: 50vw;
    margin: auto;
    border-radius: 8px;
    border: solid 1px;
    transition: background-color 0.5s ease;
    padding: 30px 30px 45px 30px;
}

.play-area-Purple {
    background-color: rgba(170, 69, 152, .2);
    border-color: var(--color-purple);
}

.play-area-Blue {
    background-color: rgba(137, 203, 236, .2);
    border-color: var(--color-blue);
}

.play-area-Yellow {
    background-color: rgba(248, 198, 76, .2);
    border-color: var(--color-yellow);
}

.play-area-Green {
    background-color: rgba(37, 134, 71, .2);
    border-color: var(--color-green);
}

.draw-card-button {
    position: relative;
    cursor: pointer;
    text-decoration: none;
    font-family: 'Carter One', sans-serif;
    font-size: 15px;
    line-height: 1.5em;
    text-shadow: 2px 2px 1px #0066a2, -2px 2px 1px #0066a2, 2px -2px 1px #0066a2, -2px -2px 1px #0066a2, 0px 2px 1px #0066a2, 0px -2px 1px #0066a2, 0px 4px 1px #004a87, 2px 4px 1px #004a87, -2px 4px 1px #004a87;
    border: none;
    margin: auto 30px;
    background: repeating-linear-gradient(45deg, #3ebbf7, #3ebbf7 5px, #45b1f4 5px, #45b1f4 10px);
    color: #fff;
    border-radius: 8px;
    padding: 8px 15px 10px;
    box-shadow: 0 6px 0 #266b91, 0 8px 1px 1px rgba(0, 0, 0, .3), 0 10px 0 5px #12517d, 0 12px 0 5px #1a6b9a, 0 15px 0 5px #0c405e, 0 15px 1px 6px rgba(0, 0, 0, .3);
}

.draw-card-button:hover {
    top: 2px;
    box-shadow: 0 4px 0 #266b91, 0 6px 1px 1px rgba(0, 0, 0, .3), 0 8px 0 5px #12517d, 0 10px 0 5px #1a6b9a, 0 13px 0 5px #0c405e, 0 13px 1px 6px rgba(0, 0, 0, .3);
}

.draw-card-pile-container {
    margin: 0 30px;
    width: 7rem;
    position: relative;
}

.draw-card-pile-images {}

.draw-card-pile-text {
    font-size: 14px;
    font-weight: 600;
    position: absolute;
    top: 170px;
    left: 30px;
}

.draw-card-pile-img {
    width: 7rem;
    border-radius: 4px;
    position: absolute;
    left: 0;

}

.draw-card-pile-img.img-2 {
    left: 15px;
}

.draw-card-pile-img.img-3 {
    left: 30px;
}

.draw-card-pile-img.drawn-card {
    left: 30px;
    transition: all .3s ease;
    z-index: 2;
}

.slap-button {
    position: relative;
    cursor: not-allowed;
    text-decoration: none;
    font-family: 'Carter One', sans-serif;
    font-size: 15px;
    line-height: 1.5em;
    text-shadow: 2px 2px 1px #0066a2, -2px 2px 1px #0066a2, 2px -2px 1px #0066a2, -2px -2px 1px #0066a2, 0px 2px 1px #0066a2, 0px -2px 1px #0066a2, 0px 4px 1px #004a87, 2px 4px 1px #004a87, -2px 4px 1px #004a87;
    border: none;
    margin: auto 30px;
    background: repeating-linear-gradient(45deg, #3ebbf7, #3ebbf7 5px, #45b1f4 5px, #45b1f4 10px);
    color: #fff;
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0 6px 0 #266b91, 0 8px 1px 1px rgba(0, 0, 0, .3), 0 10px 0 5px #12517d, 0 12px 0 5px #1a6b9a, 0 15px 0 5px #0c405e, 0 15px 1px 6px rgba(0, 0, 0, .3);
    display: flex;
    align-items: center;
    justify-content: center;
}

.slap-button:hover {
    top: 2px;
    box-shadow: 0 4px 0 #266b91, 0 6px 1px 1px rgba(0, 0, 0, .3), 0 8px 0 5px #12517d, 0 10px 0 5px #1a6b9a, 0 13px 0 5px #0c405e, 0 13px 1px 6px rgba(0, 0, 0, .3);
}

.slap-button.active {
    position: relative;
    cursor: pointer;
    text-decoration: none;
    font-family: 'Carter One', sans-serif;
    font-size: 15px;
    line-height: 1.5em;
    text-shadow: 2px 2px 1px #0066a2, -2px 2px 1px #0066a2, 2px -2px 1px #0066a2, -2px -2px 1px #0066a2, 0px 2px 1px #0066a2, 0px -2px 1px #0066a2, 0px 4px 1px #004a87, 2px 4px 1px #004a87, -2px 4px 1px #004a87;
    border: none;
    margin: auto 30px;
    background: repeating-linear-gradient(45deg, #3ebbf7, #3ebbf7 5px, #45b1f4 5px, #45b1f4 10px);
    color: #fff;
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0 6px 0 #266b91, 0 8px 1px 1px rgba(0, 0, 0, .3), 0 10px 0 5px #12517d, 0 12px 0 5px #1a6b9a, 0 15px 0 5px #0c405e, 0 15px 1px 6px rgba(0, 0, 0, .3);
    display: flex;
    align-items: center;
    justify-content: center;
}

.slap-button.active:hover {
    top: 2px;
    box-shadow: 0 4px 0 #266b91, 0 6px 1px 1px rgba(0, 0, 0, .3), 0 8px 0 5px #12517d, 0 10px 0 5px #1a6b9a, 0 13px 0 5px #0c405e, 0 13px 1px 6px rgba(0, 0, 0, .3), 0 0 20px #3ebbf7;
}

.heated-button {
    position: relative;
    cursor: not-allowed;
    text-decoration: none;
    font-family: 'Carter One', sans-serif;
    font-size: 15px;
    line-height: 1.5em;
    text-shadow: 2px 2px 1px #0066a2, -2px 2px 1px #0066a2, 2px -2px 1px #0066a2, -2px -2px 1px #0066a2, 0px 2px 1px #0066a2, 0px -2px 1px #0066a2, 0px 4px 1px #004a87, 2px 4px 1px #004a87, -2px 4px 1px #004a87;
    border: none;
    margin: auto 30px;
    background: repeating-linear-gradient(45deg, #3ebbf7, #3ebbf7 5px, #45b1f4 5px, #45b1f4 10px);
    color: #fff;
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0 6px 0 #266b91, 0 8px 1px 1px rgba(0, 0, 0, .3), 0 10px 0 5px #12517d, 0 12px 0 5px #1a6b9a, 0 15px 0 5px #0c405e, 0 15px 1px 6px rgba(0, 0, 0, .3);
    display: flex;
    align-items: center;
    justify-content: center;
}

.heated-button:hover {
    top: 2px;
    box-shadow: 0 4px 0 #266b91, 0 6px 1px 1px rgba(0, 0, 0, .3), 0 8px 0 5px #12517d, 0 10px 0 5px #1a6b9a, 0 13px 0 5px #0c405e, 0 13px 1px 6px rgba(0, 0, 0, .3);
}

.heated-button.active {
    position: relative;
    cursor: pointer;
    text-decoration: none;
    font-family: 'Carter One', sans-serif;
    font-size: 15px;
    line-height: 1.5em;
    text-shadow: 2px 2px 1px #0066a2, -2px 2px 1px #0066a2, 2px -2px 1px #0066a2, -2px -2px 1px #0066a2, 0px 2px 1px #0066a2, 0px -2px 1px #0066a2, 0px 4px 1px #004a87, 2px 4px 1px #004a87, -2px 4px 1px #004a87;
    border: none;
    margin: auto 30px;
    background: repeating-linear-gradient(45deg, #3ebbf7, #3ebbf7 5px, #45b1f4 5px, #45b1f4 10px);
    color: #fff;
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0 6px 0 #266b91, 0 8px 1px 1px rgba(0, 0, 0, .3), 0 10px 0 5px #12517d, 0 12px 0 5px #1a6b9a, 0 15px 0 5px #0c405e, 0 15px 1px 6px rgba(0, 0, 0, .3);
    display: flex;
    align-items: center;
    justify-content: center;
}

.heated-button.active:hover {
    top: 2px;
    box-shadow: 0 4px 0 #266b91, 0 6px 1px 1px rgba(0, 0, 0, .3), 0 8px 0 5px #12517d, 0 10px 0 5px #1a6b9a, 0 13px 0 5px #0c405e, 0 13px 1px 6px rgba(0, 0, 0, .3), 0 0 20px #3ebbf7;
}

.player-hand {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}

.top-discarded-heated-card {
    width: 7rem;
    margin: 2px;
}

.heated-card {
    width: 5rem;
    margin-right: -45px;
}