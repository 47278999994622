:root {
  --color-green: rgb(37, 134, 71);
  --color-blue: rgb(137, 203, 236);
  --color-yellow: rgb(248, 198, 76);
  --color-purple: rgb(170, 69, 152);

}

.ghost-pepper-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.modal-content {
  width: 100%;
  text-align: center;
}

.modal-header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-title {
  font-size: 20px;
  font-weight: bold;
}

.modal-body {
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.modal-footer {
  display: flex;
  justify-content: center;
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

@keyframes glowing {
  0% {
    box-shadow: 0 0 2px 2px rgba(255, 217, 0, 0.7);
  }

  50% {
    box-shadow: 0 0 5px 5px rgba(255, 217, 0, 0.7);
  }

  100% {
    box-shadow: 0 0 2px 2px rgba(255, 217, 0, 0.7);
  }
}

.glow {
  animation: glowing 2s infinite;
}

.color-picker-modal-button-blue {
  background-color: var(--color-blue);
}

.color-picker-modal-button-yellow {
  background-color: var(--color-yellow);
}

.color-picker-modal-button-purple {
  background-color: var(--color-purple);
}

.color-picker-modal-button-green {
  background-color: var(--color-green);
}

.player-container {
  display: flex;
  margin: 1rem 1rem 0 1rem;
  border: solid 1px gray;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
}

.ghost-pepper-spray-modal-draw-count {
  background-color: var(--color-purple);
  color: white;
  font-weight: 600;
  font-size: 18px;
  position: absolute;
  border-radius: 50px;
  padding: 5px;
  bottom: 3.5em;
  left: 4em;
}

.count-ticker {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 60px;
}

.count-ticker button {
  width: 20px;
  height: 20px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.count-ticker span {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.player-name {
  display: flex;
  flex-direction: column;
  margin: 1rem;
}

.player-button {
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: #f7f7f7;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

.player-card-count {
  margin-top: 0.5rem;
  font-size: 14px;
  color: #888;
}