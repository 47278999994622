.top-information-bar {
    background-color: #FFF;
    color: #000;
    border-radius: 8px;
    box-shadow: 0 0 10px black;
    display: flex;
    padding: 15px;
    margin: 15px auto;
    font-size: 20px;
    font-weight: 600;
}

.top-information-player-turn {
    margin-right: 15px;
    border-right: solid 1px gray;
    padding-right: 15px;
}

.top-information-current-color {
    border-right: solid 1px gray;
    padding-right: 15px;
}